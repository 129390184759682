import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { resolveUser } from '@activebrands/core-web/state/user/actions';
import { setSiteSelectorCookie } from '@activebrands/core-web/utils/handle-site-selector-cookie';
import ThemeButton from 'components/buttons/ThemeButton';
import CloseIcon from 'components/icons/CloseIcon';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const CLEAR_LOCATION_PARAM = process.env.REACT_APP_CLEAR_LOCATION_PARAM;

const Wrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    zIndex: -1,
    width: '100vw',
    height: 'auto',

    [media.min['mobile.lg']]: {
        width: '800px',
    },
});
const OverlayContent = styled('div', {
    backgroundColor: 'var(--color-bg)',
    width: '100%',

    [media.min['tablet.lg']]: {
        width: 'unset',
    },
});

const OverlayHeader = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid var(--color-border-wrongsitepopup)',
    padding: '0 0 0 12px',

    [media.min['tablet.lg']]: {
        padding: '0 12px 0 24px',
    },
});
const ButtonWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',

    [media.min['mobile.lg']]: {
        flexDirection: 'row',
    },

    [media.min['tablet.lg']]: {
        margin: '0 24px',
    },
});

const ImageWrapper = styled('div', {
    margin: '0 6px -2px 6px',
    width: '24px',
    height: '16px',
    overflow: 'hidden',
});

const StyledLink = styled('a', {
    display: 'block',
    marginTop: '12px',
    paddingLeft: ['12px', null, null, null, '24px'],
    marginBottom: '24px',
    ...textStyles['Secondary/14_130'],
    textDecoration: 'underline',
    textUnderlineOffset: '2px',
});

const WrongSiteOverlay = ({ currentSiteId, hrefUrl, suggestedSiteId }) => {
    const [css] = useStyletron();
    const dispatch = useTypedDispatch();
    const { siteSelectorLinks } = useSiteSelectorQuery();

    const suggestedSite = siteSelectorLinks.find(site => site.id == suggestedSiteId);
    const currentSite = siteSelectorLinks.find(site => site.id == currentSiteId);

    if (!suggestedSite || !currentSite) {
        return null;
    }

    const stayOnCurrentSite = () => {
        overlay.close('wrong-site-overlay');
        setSiteSelectorCookie(currentSite.path);
        dispatch(resolveUser());
    };

    const switchToSuggestedSite = () => {
        overlay.close('wrong-site-overlay');
        setSiteSelectorCookie(suggestedSite.path);
        dispatch(resolveUser());
    };

    return (
        // NOTE: These text should not be translated as they should be understandable by as many people as possible.
        <Wrapper>
            <OverlayContent>
                <OverlayHeader>
                    <Heading fontKeys="Secondary/24_100_-03">Hi!</Heading>
                    <button
                        type="button"
                        className={css({
                            padding: '14px',
                        })}
                        onClick={() => stayOnCurrentSite()}
                    >
                        <CloseIcon width="16px" />
                    </button>
                </OverlayHeader>
                <Paragraph $style={{ padding: ['12px', null, null, null, '24px'] }} fontKeys="Secondary/14_130_-0175">
                    Where would you like to shop?
                </Paragraph>
                <ButtonWrapper>
                    <ThemeButton
                        $style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '12px 36px',
                            width: ['100%', null, null, '50%'],
                            borderRadius: 0,
                            margin: 0, // compensating for the standard margin fix in ThemeButton
                            borderWidth: '0px',
                        }}
                        as="a"
                        fontKeys="Misc/16_100_-2"
                        theme="defaultsecondary"
                        href={hrefUrl || suggestedSite.path}
                        onClick={() => switchToSuggestedSite()}
                    >
                        Switch to
                        <ImageWrapper>
                            <Image
                                sizes={['24px']}
                                src={{
                                    url: `https://flagcdn.com/h20/${suggestedSite?.countryCode?.toLowerCase()}.png`,
                                    width: [16, 48, 72],
                                }}
                            />
                        </ImageWrapper>
                        {suggestedSite?.label}
                    </ThemeButton>
                    <ThemeButton
                        $style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '12px 36px',
                            width: ['100%', null, null, '50%'],
                            borderRadius: 0,
                            margin: 0, // compensating for the standard margin fix in ThemeButton
                        }}
                        fontKeys="Misc/16_100_-2"
                        theme="default"
                        onClick={() => stayOnCurrentSite()}
                    >
                        Stay on
                        <ImageWrapper>
                            <Image
                                sizes="24px"
                                src={{
                                    url: `https://flagcdn.com/h20/${currentSite?.countryCode?.toLowerCase()}.png`,
                                    width: [16, 48, 72],
                                }}
                            />
                        </ImageWrapper>
                        {currentSite?.label}
                    </ThemeButton>
                </ButtonWrapper>

                <StyledLink href={`/?${CLEAR_LOCATION_PARAM}`}>Click here to shop from a different country.</StyledLink>
            </OverlayContent>
        </Wrapper>
    );
};

WrongSiteOverlay.propTypes = {
    currentSiteId: PropTypes.number,
    hrefUrl: PropTypes.string,
    suggestedSiteId: PropTypes.number,
};

export default WrongSiteOverlay;
